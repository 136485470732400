<template>
    <transition>
        <the-alert type="danger">
            <template #header>
                Página no encontrada!
            </template>
            La página solicitada no existe. 
            Por favor, contacte con soporte.
        </the-alert>
    </transition>
</template>

<script>
import TheAlert from './../TheAlert.vue'
export default {
    components: {
        TheAlert
    }
}
</script>